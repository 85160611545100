<template>
  <div>
    <Input
      label="Çalışan Adı Soyadı *"
      v-model="form.fullName"
      :defaultValue="selectedItem.fullName"
      :state="validateState('fullName')"
    />
    <Input
      label="Email *"
      v-model="form.email"
      :defaultValue="selectedItem.email"
      :state="validateState('email')"
    />
    <Input v-if="selectedItem == ''" label="Şifre" v-model="form.password" />
    <Select
      label="Rol *"
      v-model="form.userType"
      :defaultValue="selectedItem.userType"
      :state="validateState('userType')"
      :options="[
        {
          value: 'CompanyManager',
          text: $customFunctions.getUserTypeText('CompanyManager'),
        },
        {
          value: 'StandartUser',
          text: $customFunctions.getUserTypeText('StandartUser'),
        },
      ]"
    />
    <Checkbox
      formGroupLabel="Durum *"
      label="Aktif"
      v-model="form.isActive"
      :defaultValue="
        selectedItem.isActive === true || selectedItem.isActive === false
          ? selectedItem.isActive.toString()
          : ''
      "
    />
  </div>
</template>

<script>
// Utils
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'InsertUpdateOperations',
  mixins: [validationMixin],
  props: {
    confirmModal: Boolean,
    selectedItem: String | Object,
  },
  data() {
    return {
      loading: false,
      form: {
        userType: '',
        fullName: '',
        email: '',
        isActive: true,
        password: '',
      },
    };
  },
  validations: {
    form: {
      userType: {
        required,
      },
      fullName: {
        required,
      },
      email: {
        required,
        email,
      },
      isActive: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$emit('resetInsertUpdateForm', false);
        return;
      }
      let payload = {
        userType: this.form.userType,
        fullName: this.form.fullName,
        email: this.form.email,
        isActive: this.form.isActive,
      };

      if (this.form.password) payload.password = this.form.password;

      this.$dbFunctions
        .insertOrUpdate(
          `${this.$route.params.companyId}/companyEmployee`,
          this.selectedItem.id,
          payload
        )
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('resetInsertUpdateForm', true);
          this.formReset();
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
          this.$emit('resetInsertUpdateForm', false);
        })
        .finally(() => {});
    },
    formReset() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$v.$reset();
    },
  },
  watch: {
    confirmModal(newVal) {
      if (newVal) {
        this.confirm();
      }
    },
    selectedItem(newVal) {
      this.formReset();
    },
  },
};
</script>
